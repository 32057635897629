<script lang="ts" setup>
import type { ApiError } from "@shopware/api-client";
import { ApiClientError } from "@shopware/api-client";

const emits = defineEmits<{
  (e: "success"): void;
  (e: "close"): void;
}>();
const router = useRouter();
const { login } = useUser();
const { t } = useI18n();
const { refreshSessionContext } = useSessionContext();
const { mergeWishlistProducts } = useWishlist();
const { pushSuccess } = useNotifications();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const loginErrors = ref<string[]>([]);

const pending = ref(false);

const formData = ref({
  username: "",
  password: "",
  remember: true,
});

const { resolveApiErrors } = useApiErrorsResolver("account_login");

const invokeLogin = async (): Promise<void> => {
  loginErrors.value = [];
  pending.value = true;
  try {
    await login(formData.value);
    emits("success");
    pushSuccess(t("account.messages.loggedInSuccess"));
    emits("close");
    // mergeWishlistProducts();
  } catch (error) {
    if (error instanceof ApiClientError) {
      loginErrors.value = resolveApiErrors(error.details.errors as ApiError[]);
    }
  } finally {
    pending.value = false;
  }
};

const emailImputElement = ref();
useFocus(emailImputElement, { initialValue: true });
</script>
<template>
  <div>
    <v-img alt="logo of the shop" class="mb-8" src="/logo_footer.svg" />

    <div class="text-h2 text-center mb-8">
      {{ t("account.signInLabel") }}
    </div>

    <v-form @submit.prevent="invokeLogin">
      <input
        v-model="formData.remember"
        data-testid="login-remember-input"
        name="remember"
        type="hidden"
      />

      <v-text-field
        v-model="formData.username"
        :label="t('form.email')"
        autocomplete="email"
        required
        type="email"
        variant="outlined"
      />

      <v-text-field
        v-model="formData.password"
        :label="t('form.password')"
        autocomplete="current-password"
        required
        type="password"
        variant="outlined"
      />

      <v-alert v-if="loginErrors.length" type="error" class="mb-3">
        {{ loginErrors[0] }}
      </v-alert>

      <v-btn
        block
        class="mb-4"
        color="primary"
        size="large"
        type="submit"
        variant="flat"
        :loading="pending"
      >
        {{ t("account.signIn") }}
      </v-btn>

      <v-btn
        :to="formatLink('/register')"
        block
        color="secondary"
        size="large"
        variant="flat"
        :disabled="pending"
        @click="emits('close')"
      >
        {{ t("account.signUp") }}
      </v-btn>
    </v-form>
  </div>
</template>
